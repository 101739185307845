import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box, Button, Flex, Heading,
  Link, Skeleton, Text, useDisclosure
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { BsCalendar } from "react-icons/bs";
import { IoIosSearch } from "react-icons/io";
import { useNavigate, useParams } from 'react-router-dom';
import TripMap from "../components/TripMap";
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { PastReservationWidget } from "../components/guestportal/PastReservationWidget";
import { ReservationLookup } from "../components/guestportal/ReservationLookup";
import { ReservationWidget } from "../components/guestportal/ReservationWidget";
import { TripDetails } from "../components/guestportal/TripDetails";
import TripDetailsSkeleton from "../components/guestportal/TripDetailsSkeleton";
import { WelcomePopup } from "../components/guestportal/WelcomePopup";
import { useTrips } from "../contexts/TripContext";
import { omitNullAndUndefined } from "../helpers/helpers";
import { sendHubspotEvent } from "../helpers/hubspot";

const Trips = () => {
  const navigate = useNavigate();
  const { paramReservationNumber } = useParams();

  const { user } = useUser();
  const userFirstName = user.firstName;
  const userEmail = user.primaryEmailAddress.emailAddress;
  const userFirstSignIn = user.unsafeMetadata?.loggedIn ? false : true;
  const { unsafeMetadata = {} } = user;

  if (userFirstSignIn) {
    user.update({
      unsafeMetadata: { ...unsafeMetadata, loggedIn: true },
    });
  }

  const { allTripsData, updateAllTrips, selectedTrip, setSelectedTrip, pastTrips, currentFutureTrips, currentFutureCount, isTripsContextLoaded } = useTrips();
  const [reservationSearchSuccessful, setReservationSearchSuccessful] = React.useState(false);
  const [validTripParam, setValidTripParam] = React.useState(false);

  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose,
  } = useDisclosure(); // Reservation Lookup Modal

  React.useEffect(() => {
    updateAllTrips();
  }, [user,reservationSearchSuccessful, paramReservationNumber]);

  React.useEffect(() => {
    if (currentFutureTrips && currentFutureTrips.length > 0) {
      setSelectedTrip(selectedTrip || currentFutureTrips[0] || allTripsData[0]);
    }
  }, [currentFutureTrips]);

  //console.log("selected trip", selectedTrip)

  // Handler for No Upcoming Reservations Rebook Button Opening Past Reservations Accordion
  const accordionRef = React.useRef(null);
  const { isOpen: isAccordionOpen, onToggle: toggleAccordion, onClose: closeAccordion } = useDisclosure({ defaultIsOpen: true });

  const handleButtonClick = () => {
    toggleAccordion();
    setTimeout(() => {
      accordionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100); // Adjust the delay as needed
  };

  let counter = 0;
  
  //console.log("paramReservationNumber",paramReservationNumber)

  React.useEffect(() => {
    if (paramReservationNumber && isTripsContextLoaded) {
      //console.log("paramReservationNumber",paramReservationNumber)
      // Check if the current selectedTrip is not the one specified by paramReservationNumber
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        // Find the matching trip from allTripsData using the paramReservationNumber
        const matchingTrip = allTripsData.find(trip => trip.reservationNumber === paramReservationNumber);
        // If a matching trip is found, update selectedTrip with it
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setValidTripParam(true);
        } else {
          // Optional: Handle the case where no matching trip is found (e.g., navigate to a "trip not found" page or show a message)
          //console.log('No matching trip found for reservation number:', paramReservationNumber);
          setValidTripParam(false);
          // navigate('/some-path'); // Uncomment and replace '/some-path' with your desired route
        }
      } else if (selectedTrip?.reservationNumber === paramReservationNumber) {
        setValidTripParam(true);
        //console.log("trip already loaded")
      }
    }
  }, [paramReservationNumber, isTripsContextLoaded]);


    // HubSpot Custom Event // 
  
    const eventFiredRef = React.useRef(false); // Track if event has been fired
    const eventName = "pe21053466_my_trips_page_view";
    const properties = omitNullAndUndefined({
      hs_page_url: window.location.href,
    })
    const eventData = {
      eventName,
      properties,
      ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
    };
  
    React.useEffect(() => {
      if (!eventFiredRef.current && Object.keys(properties).length > 0) {
        // Fire the event only once
        eventFiredRef.current = true;
        (async () => {
          try {
            console.log('Sending eventData:', eventData);
            await sendHubspotEvent(eventData);
          } catch (error) {
            console.error('Error sending HubSpot event:', error);
          }
        })();
      }
    }, [properties]); // Only re-run if `properties` change
  
    // END HubSpot Custom Event // 
  
  
  
  return (    
    <>
      <Flex className={'tripcontainer'} w={'100vw'}>
        { validTripParam && paramReservationNumber && selectedTrip ?
          <Flex
            pt={{ base: 2, lg: 8 }}
            justifyContent={"start"}
            alignItems={"center"}
            direction={"column"}
            w={'100vw'}
            maxW={'720px'}
            className={'trip-details-container'}
          >
            <Flex
              direction={{ base: "column" }}
              textAlign={"left"}
              justifyContent={"center"}
              maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
              pb={3}
            >
              <Box alignItems="center" fontSize={{base:16, md:20}} mb={{base:2, md:4}}>
                <Flex my={3}>
                  <ChevronLeftIcon onClick={() => navigate('/trips')} />
                  <Text as="span" cursor="pointer" ml={2} lineHeight={'16px'} onClick={() => navigate('/trips')} >
                    Back to My Trips
                  </Text>
                </Flex>
              </Box>
              <TripDetails
                userEmail={userEmail}
              />
            </Flex>
          </Flex>
          : validTripParam && paramReservationNumber && !selectedTrip ?
          <TripDetailsSkeleton />
          :
          <Flex
            pt={{ base: 2, lg: 16 }}
            justifyContent={"start"}
            alignItems={"center"}
            direction={"column"}
            w={{base:'100vw',md:'50vw'}}
            maxW={'720px'}
            className={'reservationcontainer'}
          >
            <Flex
              direction={{ base: "column" }}
              textAlign={"left"}
              justifyContent={"center"}
              maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
              pb={3}
            >
              {userFirstName &&
                <Heading
                  pb={{ base: 0, md:5 }}
                  pt={{ base: 4, sm: 6, lg: 0 }}
                  pl={{ base: 0, xl: 0 }}
                  fontWeight={"normal"}
                >
                  Welcome, {userFirstName}
                </Heading>
              }

              <Box display={{base:"none", md:"block"}} fontSize={{base:20, md:24}} fontWeight={'bold'} mb={{base:2, md:4}}>
                <h1>My Trips</h1>
              </Box>

              {!isTripsContextLoaded ? (
                <>
                  <Flex direction={{ base: "column", md: "row" }} mb={"200px"}>
                    <Skeleton
                      height="380px"
                      w={{base:'90vw',md:'50vw'}}
                      mt={3}
                      maxW={'680px'}
                      borderLeftRadius={{base:0, md:6}}
                      borderTopRadius={{base:6, md:0}}
                      mb={{base:0, md:4}}
                    />
                  </Flex>
                </>
              ) : (currentFutureCount === 0 || !currentFutureCount) ? (
                <Flex
                  direction={"column"}
                  textAlign={"left"}
                  fontSize={"lg"}
                  width={"100%"}
                  pl={{ base: 0, xl: 2 }}
                  pt={4} pb={16}
                >
                  <Flex fontSize={20} mb={4}>
                    You have no upcoming reservations, but we would love to see you again!
                  </Flex>
                  <Flex
                    flexDirection={{base:"column", md:"row"}} gap={3}
                  >
                    { allTripsData.length > 0 &&
                      <Button
                        rightIcon={<BsCalendar />}
                        className="dm-button-orange-pill explore-homes-past-reservation-widget"
                        w={{ base: "100%", md: "300px" }}
                        boxShadow="base"
                        onClick={handleButtonClick}
                      >
                        Rebook a home
                      </Button>
                    }
                    <Button
                      rightIcon={<IoIosSearch />}
                      className={allTripsData.length > 0 ? "dm-button-gray-pill explore-homes-past-reservation-widget" : "dm-button-orange-pill explore-homes-past-reservation-widget"}
                      w={{ base: "100%", md: "300px" }}
                      boxShadow="base"
                      onClick={() => window.open("/", "_blank")}
                    >
                      Explore all homes
                    </Button>
                  </Flex>
                  <Flex pt={16} pb={5} fontWeight={"bold"}>
                    <i>Expecting to see an active or upcoming reservation?</i>
                  </Flex>
                  
                  <Flex>
                    Make sure you clicked the sign-in link in the welcome email,
                    and are logged in with the same user account. Alternatively, you
                    can search for your reservation below.
                  </Flex>
                  <Link mb={5} onClick={onSearchOpen} w={{ base: "100%", md: "300px" }}>
                    <Button
                      className="dm-button-navy-outline find-reservation"
                      w={{ base: "100%", md: "300px" }}
                      boxShadow="base"
                      mt={8}
                    >
                      Find my reservation
                    </Button>
                  </Link>
                  
                </Flex>
              ) : (
                <i />
              )}
            </Flex>

            {allTripsData && currentFutureCount > 0 &&
              currentFutureTrips
                .sort((a, b) => {
                  return (
                    new Date(a.checkinDate).getTime() -
                    new Date(b.checkinDate).getTime()
                  );
                })
                .map((reservation) => {
                  if (
                    reservation.house &&
                    (reservation.status === "Booked" || reservation.status === "Modified" || reservation.status === "Closed")
                  ) {
                    return (
                      <ReservationWidget
                        key={reservation["reservationNumber"]}
                        reservationData={reservation}
                        reservationIndex={counter++}
                      />
                    );
                  } else {
                    return <i key={reservation["reservationNumber"]} />;
                  }
                })}

            <Flex
              direction={{ base: "column" }}
              justifyContent={"center"}
              maxW={"688px"} mx={'16px'} w={'calc(100% - 32px)'}
              fontWeight={'500'} pb={12} fontStyle={'italic'}
              textAlign={'center'}
            >
              Newly booked reservations may take 10-15 minutes before they are displayed.
            </Flex>

            { allTripsData && pastTrips &&
              (() => {
                pastTrips
                  .sort((a, b) => {
                    return (
                      new Date(b.checkinDate).getTime() -
                      new Date(a.checkinDate).getTime()
                    );
                  });

                if (pastTrips.length > 0) {
                  return (
                    <>
                      <Box
                        ref={accordionRef}
                        width={'100%'}
                        maxW={"720px"}
                        textAlign={"left"}
                        pb={24}
                      >
                        <Accordion
                          allowToggle
                          index={isAccordionOpen ? [0] : []}
                          onChange={toggleAccordion}
                          maxW={"680px"} mx={'20px'}
                          bgColor={'#EFEFEF'}
                        >
                          <AccordionItem>
                            <AccordionButton fontSize={24} fontWeight={'bold'}>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                fontSize={24}
                                fontWeight={"bold"}
                                my={2}
                                onClick={toggleAccordion}
                              >
                                Past Reservations
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                              px={{base:0}}
                            >
                              {pastTrips.map((reservation) => (
                              <PastReservationWidget
                                key={reservation["reservationNumber"]}
                                reservationData={reservation}
                              />
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>
                      
                    </>
                  );
                }
              })()
            }
          </Flex>
        }
        <Flex className="tripmapcontainer" h={{base:'calc(100vh - 150px)',md:'calc(100vh - 90px)'}} 
          w={{ base: '100%' }}
          position={'sticky'} right='0px' top='80px'
          display={{base:'none',md:'flex'}}
          >
          {validTripParam && paramReservationNumber && selectedTrip ?
            <TripMap navigate={navigate} reservations={[selectedTrip]} />
            :
            <TripMap navigate={navigate} reservations={currentFutureTrips} />
          }
        </Flex>
      </Flex>

      {isTripsContextLoaded &&
      <WelcomePopup modalInitiateOpen={userFirstSignIn} />
      }

      {isTripsContextLoaded &&
      <ReservationLookup
        modalInitiateOpen={isSearchOpen}
        reservationData={allTripsData}
        onModalClose={onSearchClose}
        setReservationSearchSuccessful={setReservationSearchSuccessful}
        reservationSearchSuccessful={reservationSearchSuccessful}
      />
      }

      {isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};

export { Trips };

