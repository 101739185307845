import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  Wrap, WrapItem, useToast
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { createGuests } from "../../helpers/guestportalfetch";
import { omitNullAndUndefined } from "../../helpers/helpers";
import { sendHubspotEvent } from "../../helpers/hubspot";

type Props = {
  reservationData: any;
  modalInitiateOpen: boolean;
  onModalClose: Function;
};

const ShareWidget: React.FC<Props> = ({
  reservationData,
  modalInitiateOpen,
  onModalClose
}) => {
  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;

  const [modalOpen, setModalState] = React.useState(modalInitiateOpen);

  function openModal() {
    setModalState(true);
  }
  function closeModal() {
    setModalState(false);
  }

  function modalClose() {
    closeModal();
    onModalClose();
    setShareFormSubmitted(true);
  }

  React.useEffect(() => {
    setModalState(modalInitiateOpen);
  }, [modalInitiateOpen]);

  React.useEffect(() => {
    let guestArray: any = [];
    for (var i = 0; i < reservationData["house"]["maxOccupancy"]; i++) {
      if (i <= reservationData.guests.length - 1) {
        let guest = reservationData.guests[i];
        guestArray.push({email:guest.email, firstname:guest.firstname, lastname:guest.lastname, type: guest.guestType, error: false, hasGuest: true})
      }
      else {
        guestArray.push({ error: false, hasGuest: false });
      }
    }
    setGuestsArray([...guestArray]);
  }, [reservationData]);

  let nextYearCheckIn = new Date();
  nextYearCheckIn.setDate(nextYearCheckIn.getDate() + 366);

  let nextYearCheckOut = new Date();
  nextYearCheckOut.setDate(nextYearCheckOut.getDate() + 373);

  const [shareFormSubmitted, setShareFormSubmitted] = React.useState(false); // Share Post-Submission Modal Text
  const toast = useToast()
  const [guestsArray, setGuestsArray] = React.useState([]);

  function sendGuestInfo() {
    let firstNames = document.getElementsByClassName("guest-first-name");
    let lastNames = document.getElementsByClassName("guest-last-name");
    let emails = document.getElementsByClassName("guest-email");

    var guestsObject: any = {};
    guestsObject.reservationId = reservationData["hubspotReservationId"];
    guestsObject.reservationNumber = reservationData["reservationNumber"];
    
    var guests: any = [];
    var guestArray: any = JSON.parse(JSON.stringify(guestsArray));
    let guestArrayLength = guestArray.length - firstNames.length;
    //console.log("Guest Array Length",guestArrayLength);
    var formComplete = true;

    for (var i = 0; i < firstNames.length; i++) {
      if(!(firstNames[i] as HTMLInputElement).disabled){
        //console.log(i)
        var guest: any = {};
        guest.firstname = (firstNames[i] as HTMLInputElement).value;
        guest.lastname = (lastNames[i] as HTMLInputElement).value;
        guest.email = (emails[i] as HTMLInputElement).value;
        guest.most_recent_inviter = guestArray[0].firstname + " " + guestArray[0].lastname // most_recent_inviter is the HubSpot API Name. Do not change variable name.
        //console.log("Inviter Name: ",guest.inviter)

        if (guest.firstname !== "" && guest.lastname !== "" && guest.email !== ""){
          guestArray[i+guestArrayLength] = {email:guest.email, firstname:guest.firstname, lastname:guest.lastname, error: false, hasGuest:true };
        }
        else if(guest.firstname === "" && guest.lastname === "" && guest.email === ""){
          guestArray[i+guestArrayLength] = {email:guest.email, firstname:guest.firstname, lastname:guest.lastname, error: false, hasGuest:false };
        } 
        else{
          guestArray[i+guestArrayLength] = {email:guest.email, firstname:guest.firstname, lastname:guest.lastname, error: true, hasGuest:false };
          formComplete = false;
        }

        if (
          guest.firstname !== "" &&
          guest.lastname !== "" &&
          guest.email !== ""
        ) {
          guests.push(guest);
        }
      }
    }

    guestsObject.guests = guests;

    setGuestsArray([...guestArray]);

    if (formComplete) {

      // HubSpot Custom Event // 

      const eventName = "pe21053466_trip_shared";
      const properties = omitNullAndUndefined({
        house_number: reservationData.houseNumber,
        reservation_number: reservationData.reservationNumber,
        hs_page_url: window.location.href,
      })
      const eventData = {
        eventName,
        properties,
        ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
      };

      if (userEmail) {
        // Fire and forget
        (async () => {
          try {
            await sendHubspotEvent(eventData);
          } catch (error) {
            console.error("Error sending HubSpot event:", error);
          }
        })();
      }

      // END HubSpot Custom Event // 

      createGuests(guestsObject).then((response) => {
        modalClose();
      });
    }
  }
  let noGuestCount = guestsArray.filter(guest => 
    (guest.hasOwnProperty('hasGuest') && guest.hasGuest === false) || 
    (!guest.hasOwnProperty('firstname') && !guest.hasOwnProperty('lastname'))).length;

  return (
    <>
      {/* Share Trip Modal */}
      <Modal isOpen={modalOpen} onClose={() => void 0} size={{base:'full',md:'6xl'}}>
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent borderRadius="none">
          <ModalCloseButton size="lg" onClick={modalClose} />
          <ModalBody padding="0">
            <Flex align="center">
              <Flex
                direction="column"
                align="center"
                flex="1"
                py="12"
                px={{ base: "2", md: "4" }}
              >
                <Box maxW="3xl" mx="auto">
                  <Box
                    textAlign="center"
                    maxW={{ base: "full", sm: "3xl" }}
                    mx={{ base: "3", md: "auto" }}
                    mt="2"
                  >
                    <Heading fontWeight="extrabold">
                      Share your trip details and <br />
                      vacation easier.
                    </Heading>
                    <Text
                      fontSize={{ base: "md", md: "lg" }}
                      textAlign={{ base: "center", md: "center" }}
                      mt="6"
                      mb="3"
                    >
                      When everyone is on the same page, vacationing is easier.
                      Send friends and family all the info they'll need before
                      and during the trip, so you can all start relaxing early.
                      Don't worry, all your personal and financial information
                      is only available to you.
                    </Text>
                    <Text textAlign={'left'} fontSize={'20'} pt={5}>Here's who is invited so far on Reservation #{reservationData.reservationNumber}:</Text>
                    <Flex direction="row" py={5}>
                    
                      <Flex>
                        <Wrap spacing="30px">
                          {guestsArray.map((e, i) =>
                          e.hasGuest && 
                            <WrapItem key={i}>
                              <VStack>
                                <Avatar name={(e.firstname || '') + (e.firstname && e.lastname ? ' ' : '') + (e.lastname || '')} size='lg' />
                                {e.type === 'Primary' ? 
                                <Text>{e.firstname} (Host)</Text>
                                :
                                <Text>{e.firstname}</Text>
                                }
                              </VStack>
                            </WrapItem>
                          )}
                        </Wrap>
                      </Flex>
                    </Flex>

                    {/* Only Show Invite Additional Guests Section if Slots Available */}
                    {noGuestCount !=0 &&
                    <Flex direction="column" py="2">
                      <Text textAlign={'left'} fontSize={'20'} py={3}>Invite additional guests:</Text>
                      {guestsArray.map((e, i) => {
                        return (
                          <React.Fragment key={i}>
                            {!e.hasGuest && (
                              <Flex py="2" flexWrap={{ base: "wrap", md: "nowrap" }}>
                                <Input
                                  className="guest-first-name"
                                  placeholder="first name"
                                  mr={{ base: "0", md: "2" }}
                                  w={"50%"}
                                  defaultValue={e.firstname ? e.firstname : ''}
                                  disabled = {e.hasGuest ? true : false}
                                />
                                <Input
                                  className="guest-last-name"
                                  placeholder="last name"
                                  mr={{ base: "0", md: "2" }}
                                  w={"50%"}
                                  defaultValue={e.lastname ? e.lastname : ''}
                                  disabled = {e.hasGuest ? true : false}
                                />
                                <Input
                                  className="guest-email"
                                  placeholder="email address"
                                  defaultValue={e.email ? e.email : ''}
                                  disabled = {e.hasGuest ? true : false}
                                />
                              </Flex>
                            )}
                            {e.error && (
                              <span className="left-align-text red-text">
                                Please enter recipients name and email.
                              </span>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Flex>
                    }
                    
                    {/* Share Trip Button */}
                    <Flex
                      w={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      pt={4}
                    >
                      <Link
                        href="#homeinfo"
                        m={2}
                        w={{ base: "100%", lg: "100%" }}
                        className="no-underline"
                      >
                        <Button
                          onClick={() => {
                            sendGuestInfo();
                            // Use setTimeout to delay the toast
                            setTimeout(() => {
                              toast({
                                title: 'Trip Shared',
                                description: "Your trip has been shared with your guests.",
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                                position: 'top',
                              });
                            }, 750);
                          }}
                          className="dm-button-orange-pill share-modal-submit"
                          w={{ base: "100%", lg: "100%" }}
                          boxShadow="base"
                        >
                          Share this trip
                        </Button>
                      </Link>
                    </Flex>
                    {/* End Share Trip Button */}
                    {/* End Show Invite Additional Guests Section */}

                  </Box>
                </Box>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* End Share Trip Modal */}
    </>
  );
};

export { ShareWidget };

