import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react";
import { addDays } from "date-fns";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import { BsPerson } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdPets } from "react-icons/md";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { formatFriendlyDateShorter } from "../../../helpers/helpers";
import { MAX_GUESTS, MIN_GUESTS, TOWNS } from "./constants";

export const MobileSearch = ({
  isModalOpen, // Use the prop passed from the parent
  openModal,
  closeModal,
  datesToGrayOut,
  setTown,
  town,
  startDate,
  endDate,
  conditionalCalendarProps,
  decrementGuestInput,
  incrementGuestInput,
  closeModalAndSearch,
}) => {
  const {
    guests,
    setGuests,
    dogs,
    setDogs,
    setContextDateRange,
    globalCalendarLoading,
    mapAreaSearch,
  } = useContext(SearchResultsContext);

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={"500px"}
      h={"40px"}
      mr={0}
    >
      <Flex
        color={"dmNavy.500"}
        bgColor={"white"}
        borderRadius={"24px"}
        h={"40px"}
        w={"calc(100vw - 96px)"}
        maxW={"100%"}
        px={3}
        alignItems="center"
        onClick={openModal} // Use the prop function
        justifyContent={"space-between"}
        border="solid 1px #00000038"
      >
        <Modal isOpen={isModalOpen} onClose={closeModal} size={"full"}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              {/* Town Dropdown */}
              <Text color={"dmNavy.500"} mt={6}>
                Where would you like to go?
              </Text>
              <FormControl w={"100%"} mt={2}>
                <Select
                  value={mapAreaSearch ? "Map Area" : town || ""}
                  onChange={(e) => setTown(e.target.value || null)}
                  bgColor={"white"}
                  color={"black"}
                  fontSize={16}
                  borderRadius={6}
                  border={"solid 1px"}
                >
                  <option placeholder="All Towns" value="All Towns">
                    All Towns
                  </option>
                  {TOWNS.map((town) => (
                    <option key={town} value={town}>
                      {town}
                    </option>
                  ))}
                  {mapAreaSearch && <option value="Map Area">Map Area</option>}
                </Select>
              </FormControl>

              {/* Date Picker */}
              <Text color={"dmNavy.500"} mt={6}>
                When are you going?
              </Text>

              {!globalCalendarLoading && (
                <FormControl
                  w={"100%"}
                  h={"40px"}
                  minW={"205px"}
                  fontSize={16}
                  mt={2}
                  inputMode="none"
                >
                  <Flex
                    color={"black"}
                    height={"40px"}
                    w={"100%"}
                    h={"100%"}
                    className="mobile-menu-date-picker"
                  >
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setContextDateRange(update);
                      }}
                      isClearable={true}
                      placeholderText="Select Dates"
                      h={"100%"}
                      dateFormat={"MMMM d, yyyy"}
                      excludeDates={datesToGrayOut}
                      filterDate={(date) => {
                        // If there is a start date, ensure that the end date is at least 2 days after
                        if (startDate) {
                          return date >= addDays(startDate, 2);
                        }
                        return true; // Allow all dates if no start date is selected
                      }}
                      {...conditionalCalendarProps}
                    />
                  </Flex>
                </FormControl>
              )}

              {/* Guests Number Input */}
              <Flex
                alignContent={"center"}
                gap={3}
                mt={6}
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"100%"}
              >
                <Text color={"dmNavy.500"} minW="180px">
                  How many guests?
                </Text>
                <FormControl w="fit-content">
                  <Flex gap={2}>
                    <Button
                      onClick={decrementGuestInput}
                      variant="outline"
                      borderColor="dmNavy.500"
                    >
                      -
                    </Button>
                    <Input
                      textAlign={"center"}
                      p="10px"
                      w="40px"
                      color="black"
                      type="number"
                      border="0"
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (
                          !isNaN(newValue) &&
                          newValue >= MIN_GUESTS &&
                          newValue <= MAX_GUESTS
                        ) {
                          setGuests(newValue);
                        }
                      }}
                      value={guests || 1}
                    />
                    <Button
                      onClick={incrementGuestInput}
                      variant="outline"
                      borderColor="dmNavy.500"
                    >
                      +
                    </Button>
                  </Flex>
                </FormControl>
              </Flex>

              {/* Dog Number Input */}
              <FormControl w={"100%"} mt={8}>
                <Flex
                  alignItems={"center"}
                  gap={4}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <Text color={"dmNavy.500"} minW="180px">
                    Dog-friendly homes?
                    <br />
                    (max 2 with fee)
                  </Text>
                  <Flex flexDirection={"column"} alignItems="center" w="140px">
                    <Switch
                      id="dogs-switch"
                      colorScheme="dmNavy"
                      isChecked={dogs}
                      onChange={(e) => setDogs(e.target.checked)}
                      size="lg"
                    />
                    <Text fontSize="xs" mt={2} lineHeight={0}>
                      {dogs ? "Yes" : "No"}
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Flex w={"100%"} justifyContent={"end"}>
                <Button
                  onClick={closeModalAndSearch} // Use the prop function
                  w={"100%"}
                  borderRadius={6}
                  mt={6}
                  size={"lg"}
                  bgColor={"dmOrange.500"}
                  color={"white"}
                  _hover={{ bg: "dmOrange.600" }}
                >
                  <FiSearch />
                  <Text ml={2}>Search</Text>
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Flex>
          <FiSearch className="searchicon" />
          <Flex fontSize={"12px"} mx={2}>
            {town ? (
              town
            ) : (
              <Flex alignItems="center">
                <Text mr={1} fontSize="sm">
                  Town
                </Text>
                <FaAngleDown />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          fontSize={"12px"}
          mx={2}
          justifyItems="center"
          textAlign={"center"}
        >
          {startDate && endDate ? (
            <Box>
              <Text as="span" style={{ whiteSpace: "nowrap" }}>
                {formatFriendlyDateShorter(startDate)}
              </Text>
              <Text as="span"> - </Text>
              <Text as="span" style={{ whiteSpace: "nowrap" }}>
                {formatFriendlyDateShorter(endDate)}
              </Text>
            </Box>
          ) : (
            <Text fontSize={"sm"}>Select dates</Text>
          )}
        </Flex>
        <Flex alignItems={"center"}>
          <Flex fontSize={"11.5px"} ml={1} mr={"2px"}>
            {" "}
            {guests && `${guests}`}
          </Flex>
          <BsPerson width={16} />
          {dogs && (
            <>
              <Flex fontSize={"11.5px"} ml={"3px"} mr={"2px"}>
                {dogs}
              </Flex>
              <Box mt={"-1.5px"}>
                <MdPets width={16} color="#e95037" />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
