import UAParser from 'ua-parser-js';
import config from "../config";
import { HubspotCustomEventParams } from "../types/hubspot";
import { omitNullAndUndefined } from "./helpers";

const API_ENDPOINT = config.apiEndpoint;

export const getHubspotUTK = () => {
  const cookies = document.cookie.split(";");
  const hubspotCookie = cookies.find((cookie) =>
    cookie.trim().startsWith("hubspotutk=")
  );
  //console.log('hubspotCookie:', hubspotCookie ? hubspotCookie.split("=")[1] : null);
  return hubspotCookie ? hubspotCookie.split("=")[1] : null;
};


async function getGeolocation() {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return {
      country: data.country_name,
      region: data.region,
      city: data.city,
    };
  } catch (error) {
    console.error("Error fetching geolocation data:", error);
    return {
      country: null,
      region: null,
      city: null,
    };
  }
}

export const sendHubspotEvent = async (
  { eventName, properties, email }: HubspotCustomEventParams
): Promise<any> => {

  const hubspotUTK = getHubspotUTK();
  const url = `${API_ENDPOINT}/hsforms/hubspotCustomEvent`;
  const userAgent = navigator.userAgent;

  if (!eventName || !properties || (!email && !hubspotUTK)) {
    return;
  }

  // Fetch geolocation data
  const geolocation = await getGeolocation();

  // Parse the user agent to get device and browser information
  const parser = new UAParser(userAgent);
  const uaResult = parser.getResult();

  // Extract UTM parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const hs_utm_campaign = urlParams.get('utm_campaign');
  const hs_utm_content = urlParams.get('utm_content');
  const hs_utm_medium = urlParams.get('utm_medium');
  const hs_utm_source = urlParams.get('utm_source');
  const hs_utm_term = urlParams.get('utm_term');

  // Normalize device type to "Smartphone", "Desktop", or "Tablet"
  const rawDeviceType = uaResult.device.type || '';
  let normalizedDeviceType = rawDeviceType
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  // Map "Mobile" or "mobile" to "Smartphone"
  if (normalizedDeviceType === 'Mobile') {
    normalizedDeviceType = 'Smartphone';
  }

  const hs_device_type = 
    ["Game Console", "PDA", "Personal Computer", "Smart TV", "Wearable Computer", "Unknown", "Smartphone", "Desktop", "Tablet"].includes(normalizedDeviceType) 
    ? normalizedDeviceType 
    : "Unknown";

  // Add additional information to properties
  const enhancedProperties = {
    ...properties,
    hs_user_agent: userAgent || undefined,
    hs_city: geolocation.city || undefined,
    hs_country: geolocation.country || undefined,
    hs_region: geolocation.region || undefined,
    hs_utm_campaign: hs_utm_campaign || undefined,
    hs_utm_content: hs_utm_content || undefined,
    hs_utm_medium: hs_utm_medium || undefined,
    hs_utm_source: hs_utm_source || undefined,
    hs_utm_term: hs_utm_term || undefined,
    hs_page_title: document.title || undefined,
    hs_device_name: uaResult.device.model || undefined,
    hs_browser: uaResult.browser.name || undefined,
    hs_device_type: hs_device_type,
    hs_operating_system: uaResult.os.name || undefined,
  };

  const eventData = omitNullAndUndefined({
    eventName,
    properties: enhancedProperties,
    objectType: "contacts",
    email: email || undefined,
    utk: hubspotUTK || undefined,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eventData),
    });

    if (!response.ok) {
      throw new Error(`Error sending event: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};