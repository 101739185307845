import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure, useToast
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { addDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import * as React from "react";
import { BsCalendar } from "react-icons/bs";
import { FaShare } from "react-icons/fa";
import { fetchSupportManager } from '../../helpers/guestportalfetch';
import { GuestServicesPopOver } from "./GuestServicesPopOver";
import { RebookCalendar } from "./RebookCalendar";
import { ShareWidget } from "./ShareWidget";

interface Manager {
  name: string;
  title: string;
  avatar: string;
}

type Props = {
  activeReservationData: any;
  contactOwner: any;
};

const MobileMenu: React.FC<Props> = ({
  activeReservationData,
  contactOwner,
}) => {
  const {
    isOpen: isRebookOpen,
    onOpen: onRebookOpen,
    onClose: onRebookClose,
  } = useDisclosure(); // Rebook Modal
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure(); // Share Modal
  const {
    isOpen: isSupportOpen,
    onOpen: onSupportOpen,
    onClose: onSupportClose,
  } = useDisclosure(); // Support Modal

  const { user } = useUser();
  const [manager, setManager] = React.useState<Manager | null>(null);
  const [shareActive, setShareActive] = React.useState(true);
  const userEmail = user.primaryEmailAddress.emailAddress;
  const toast = useToast();

  React.useEffect(() => {
    const fetchData = async () => {
      if(user) {
        try {
          const response = await fetchSupportManager({ tenantEmail: userEmail });
          const managerInfo = await response.json();
          setManager(managerInfo[0]);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchData();
  }, [user]);

  const onExploreClick = () => {
    window.open('/','_self')
  };

  const easternTz = 'America/New_York';

  // Get the current date and time in Eastern Time
  const now = new Date();
  const nowInEasternTime = utcToZonedTime(now, easternTz);
  
  // Calculate the cutoff time for past reservations as 24 hours after the current time
  const oneDayAfterNowInEasternTime = addDays(nowInEasternTime, 1);
  
  // Convert the cutoff time back to UTC for comparing
  const cutoffTimeUtc = zonedTimeToUtc(oneDayAfterNowInEasternTime, easternTz);
  
  // Prepare the checkout DateTime string from the active reservation data
  const checkoutDateTimeString = `${activeReservationData.checkoutDate}T${activeReservationData.checkoutTime}:00`;
  // Convert the checkout DateTime to UTC for accurate comparison
  const checkoutDateTimeInEasternTime = zonedTimeToUtc(checkoutDateTimeString, easternTz);
  
  React.useEffect(() => {
    // Set shareActive based on whether the checkout time is at least 24 hours in the past
    // If checkout time is greater than or equal to the cutoff time (one day after now), it's still considered active/current
    setShareActive(checkoutDateTimeInEasternTime >= cutoffTimeUtc);
  }, []);  
  
  return (
    <>
      <Box
        className="floatingBottomMenu"
        display={{ base: "none", md: "none" }}
        width="100%"
        height="60px"
        position={"fixed"}
        bottom={"10px"}
        zIndex={"1000"}
      >
        <Flex
          background={"dmNavy.500"}
          margin={"0 auto"}
          width="95vw"
          borderRadius="30px"
          height="60px"
          justifyContent={"space-evenly"}
          shadow={"dmShadow"}
          px={5}
        >
          <Flex
            direction={"column"}
            color="white"
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            flex={"1 1 0"}
            onClick={activeReservationData.house.activeListing ? onRebookOpen : onExploreClick}
            className="mobile-menu-rebook cursor-pointer"
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                activeReservationData.house.activeListing ? onRebookOpen() : onExploreClick();
              }
            }}
          >
            <Box pt={1} className="mobile-menu-rebook">
              <BsCalendar className="mobile-menu-rebook" />
            </Box>
            <Box pt={1} fontSize="sm" className="mobile-menu-rebook">
              {activeReservationData.house.activeListing ? 'Rebook' : 'New Booking'}
            </Box>
          </Flex>
          <Flex
            direction={"column"}
            color="white"
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            flex={"1 1 0"}
            onClick={onSupportOpen}
            className="cursor-pointer mobile-menu-support"
          >
            {manager &&
            <Box className="mobile-menu-support">
              <Avatar
                size="lg"
                mt={"-2.5rem"}
                name={manager.name}
                src={manager.avatar}
                shadow={"dmShadow"}
                className="mobile-menu-support"
              />
            </Box>
            }
            <Box pt={1} pb={1} fontSize="sm" className="mobile-menu-support">
              Support
            </Box>
          </Flex>
          <Flex
            direction={"column"}
            color="white"
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            flex={"1 1 0"}
            onClick={() => { 
              if (shareActive) {
                onShareOpen();
              } else {
                toast({
                  title: "Notification",
                  description: "There is no active reservation to share.",
                  status: "info",
                  duration: 5000,
                  isClosable: true,
                });
              }
            }}
            className="cursor-pointer mobile-menu-share"
          >
            <Box pt={1} className="mobile-menu-share">
              <FaShare className="mobile-menu-share" />
            </Box>
            <Box pt={1} fontSize="sm" className="mobile-menu-share">
              Share
            </Box>
          </Flex>
        </Flex>
      </Box>

      <RebookCalendar
        modalInitiateOpen={isRebookOpen}
        reservationData={activeReservationData}
        onModalClose={onRebookClose}
      />

      <ShareWidget
        modalInitiateOpen={isShareOpen}
        reservationData={activeReservationData}
        onModalClose={onShareClose}
      />

      <Modal
        isOpen={isSupportOpen}
        onClose={() => void 0}
        isCentered
        size={"lg"}
      >
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent borderRadius="none" mx="0">
          <ModalCloseButton size="lg" onClick={onSupportClose} />
          <ModalBody padding="0">
          {manager &&
            <GuestServicesPopOver
              onPopoverClose={onSupportClose}
              userEmail={userEmail}
              contactOwner={manager}
            />
          }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export { MobileMenu };

