import {
  Avatar,
  Box, Button, Flex, Heading, Image,
  Input,
  Link, Spinner, Text,
  VStack,
  Wrap, WrapItem,
  useToast
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { useParams } from 'react-router-dom';
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { useTrips } from "../contexts/TripContext";
import { createGuests } from "../helpers/guestportalfetch";
import { omitNullAndUndefined } from "../helpers/helpers";
import { sendHubspotEvent } from "../helpers/hubspot";

const Share = () => {
  const { paramReservationNumber } = useParams();

  const { allTripsData, updateAllTrips, selectedTrip, setSelectedTrip, isTripsContextLoaded } = useTrips();
  const [reservationLoaded, setReservationLoaded] = React.useState(false);

  const [guestsArray, setGuestsArray] = React.useState([]);
  const toast = useToast();

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;

  //console.log("selected trip", selectedTrip)
  //console.log("paramReservationNumber",paramReservationNumber)

  // Google is Default Review Redirect if Not Airbnb or VRBO Types
  React.useEffect(() => {
    updateAllTrips();
  }, [paramReservationNumber]);

  React.useEffect(() => {
    if (paramReservationNumber && isTripsContextLoaded) {
      //console.log("paramReservationNumber",paramReservationNumber)
      // Check if the current selectedTrip is not the one specified by paramReservationNumber
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        // Find the matching trip from allTripsData using the paramReservationNumber
        const matchingTrip = allTripsData.find(trip => trip.reservationNumber === paramReservationNumber);
        // If a matching trip is found, update selectedTrip with it
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setReservationLoaded(true);

        } else {
          // Optional: Handle the case where no matching trip is found (e.g., navigate to a "trip not found" page or show a message)
          //console.log('No matching trip found for reservation number:', paramReservationNumber);
          setReservationLoaded(true);
          // navigate('/some-path'); // Uncomment and replace '/some-path' with your desired route
        }
      } else if (selectedTrip?.reservationNumber === paramReservationNumber) {
        setReservationLoaded(true);
        //console.log("trip already loaded")
      }
    }
  }, [paramReservationNumber, isTripsContextLoaded]);

  React.useEffect(() => {
    if (selectedTrip) {
      //console.log("Active Reservation for Guest Array",selectedTrip)
      let guestArray: any = [];
      for (var i = 0; i < selectedTrip["house"]["maxOccupancy"]; i++) {
        if (i <= selectedTrip.guests.length - 1) {
          let guest = selectedTrip.guests[i];
          guestArray.push({email:guest.email, firstname:guest.firstname, lastname:guest.lastname, type: guest.guestType, error: false, hasGuest: true})
        }
        else {
          guestArray.push({ error: false, hasGuest: false });
        }
      }
      setGuestsArray([...guestArray]);
    }
  }, [selectedTrip]);

  const sendGuestInfo = () => {
    let firstNames = document.getElementsByClassName("guest-first-name");
    let lastNames = document.getElementsByClassName("guest-last-name");
    let emails = document.getElementsByClassName("guest-email");

    var guestsObject: any = {};
    guestsObject.reservationId = selectedTrip["hubspotReservationId"];
    guestsObject.reservationNumber = selectedTrip["reservationNumber"];
    
    var guests: any = [];
    var guestArray: any = JSON.parse(JSON.stringify(guestsArray));
    let guestArrayLength = guestArray.length - firstNames.length;
    //console.log("Guest Array Length",guestArrayLength);
    var formComplete = true;

    for (var i = 0; i < firstNames.length; i++) {
      if(!(firstNames[i] as HTMLInputElement).disabled){
        //console.log(i)
        var guest: any = {};
        guest.firstname = (firstNames[i] as HTMLInputElement).value;
        guest.lastname = (lastNames[i] as HTMLInputElement).value;
        guest.email = (emails[i] as HTMLInputElement).value;
        guest.most_recent_inviter = guestArray[0].firstname + " " + guestArray[0].lastname // most_recent_inviter is the HubSpot API Name. Do not change variable name.
        //console.log("Inviter Name: ",guest.inviter)

        if (guest.firstname !== "" && guest.lastname !== "" && guest.email !== ""){
          guestArray[i+guestArrayLength] = {email:guest.email, firstname:guest.firstname, lastname:guest.lastname, error: false, hasGuest:true };
        }
        else if(guest.firstname === "" && guest.lastname === "" && guest.email === ""){
          guestArray[i+guestArrayLength] = {email:guest.email, firstname:guest.firstname, lastname:guest.lastname, error: false, hasGuest:false };
        } 
        else{
          guestArray[i+guestArrayLength] = {email:guest.email, firstname:guest.firstname, lastname:guest.lastname, error: true, hasGuest:false };
          formComplete = false;
        }

        if (
          guest.firstname !== "" &&
          guest.lastname !== "" &&
          guest.email !== ""
        ) {
          guests.push(guest);
        }
      }
    }

    guestsObject.guests = guests;

    setGuestsArray([...guestArray]);

    if (formComplete) {

      // HubSpot Custom Event // 

      const eventName = "pe21053466_trip_shared";
      const properties = omitNullAndUndefined({
        house_number: selectedTrip.houseNumber,
        reservation_number: selectedTrip.reservationNumber,
        hs_page_url: window.location.href,
      })
      const eventData = {
        eventName,
        properties,
        ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
      };

      if (userEmail) {
        // Fire and forget
        (async () => {
          try {
            await sendHubspotEvent(eventData);
          } catch (error) {
            console.error("Error sending HubSpot event:", error);
          }
        })();
      }

      // END HubSpot Custom Event // 

      createGuests(guestsObject).then((response) => {
        return;
      });
      
    }



  }
  let noGuestCount = guestsArray.filter(guest => 
    (guest.hasOwnProperty('hasGuest') && guest.hasGuest === false) || 
    (!guest.hasOwnProperty('firstname') && !guest.hasOwnProperty('lastname'))).length;

  return (
    <>
      <Flex textAlign="center" fontSize="xl" bg={"white"} minH={{base:0,md:'1100px'}} w={'100vw'}>
        {paramReservationNumber && selectedTrip ?
        <Flex
          bgColor="gray.50"
          width={{base:"100vw"}}
          flexDirection={'column'}
        >
          <Flex direction={"column"} alignItems={"start"} position="relative" textAlign={'start'} pt={{base:4, md:16}} px={{base:8, md:20, lg:12, xl:20}}>
            <Text as="span" flexShrink={0} pt={3} pb={1} display={{base:'none',md:'unset'}}>Currently sharing:</Text>
            <Flex direction={"column"} position="relative" w={'100%'} id="level4">
              {selectedTrip && (
                <Box
                  bg={"white"}
                  mt={3} pr={{base:2, sm:6}}
                  w={{base:'100%'}}
                  border={'solid 1px rgba(83, 180, 255, 1)'} 
                >
                  <Flex height={'100%'}>
                    <Image
                      objectFit="cover"
                      src={selectedTrip.house.images[0].link}
                      alt="House"
                      mr={3}
                      height={'100%'}
                      maxW={'100px'}
                    />
                    <Flex direction={'column'} justifyContent="center" fontSize={{base:16, sm:18, md:20, lg:16, xl:18}} my={3}>
                      <Text>Reservation #{selectedTrip.reservationNumber}</Text>
                      <Text mt={1}>House {selectedTrip.house.houseNumber} {selectedTrip.house.name} </Text>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Flex>
          </Flex>

          {selectedTrip ? (
            <Flex align="center">
              <Flex
                direction="column"
                align="center"
                flex="1"
                py={{base:2,md:12}}
                px={{ base: "2", md: "4" }}
              >
                <Box maxW="3xl" mx="auto">
                  <Box
                    textAlign="center"
                    maxW={{ base: "full", sm: "3xl" }}
                    mx={{ base: "3", md: "auto" }}
                    mt="2"
                  >
                    <Heading fontWeight="extrabold">
                      Share your trip details and 
                      vacation easier.
                    </Heading>
                    <Text
                      fontSize={{ base: "md", md: "lg" }}
                      textAlign={{ base: "center", md: "center" }}
                      mt="6"
                      mb="3"
                    >
                      When everyone is on the same page, vacationing is easier.
                      Send friends and family all the info they'll need before
                      and during the trip, so you can all start relaxing early.
                      Don't worry, all your personal and financial information
                      is only available to you.
                    </Text>
                    <Text textAlign={'left'} fontSize={'20'} pt={5}>Here's who is invited so far on Reservation #{selectedTrip.reservationNumber}:</Text>
                    <Flex direction="row" py={5}>
                    
                      <Flex>
                        <Wrap spacing="30px">
                          {guestsArray.map((e, i) =>
                          e.hasGuest && 
                            <WrapItem key={i}>
                              <VStack>
                                <Avatar name={(e.firstname || '') + (e.firstname && e.lastname ? ' ' : '') + (e.lastname || '')} size='lg' />
                                {e.type === 'Primary' ? 
                                <Text>{e.firstname} (Host)</Text>
                                :
                                <Text>{e.firstname}</Text>
                                }
                              </VStack>
                            </WrapItem>
                          )}
                        </Wrap>
                      </Flex>
                    </Flex>

                    {/* Only Show Invite Additional Guests Section if Slots Available */}
                    {noGuestCount !=0 &&
                    <Flex direction="column" py="2">
                      <Text textAlign={'left'} fontSize={'20'} py={3}>Invite additional guests:</Text>
                      {guestsArray.map((e, i) => {
                        return (
                          <React.Fragment key={i}>
                            {!e.hasGuest && (
                              <Flex py="2" flexWrap={{ base: "wrap", md: "nowrap" }}>
                                <Input
                                  className="guest-first-name"
                                  placeholder="first name"
                                  mr={{ base: "0", md: "2" }}
                                  w={"50%"}
                                  defaultValue={e.firstname ? e.firstname : ''}
                                  disabled = {e.hasGuest ? true : false}
                                />
                                <Input
                                  className="guest-last-name"
                                  placeholder="last name"
                                  mr={{ base: "0", md: "2" }}
                                  w={"50%"}
                                  defaultValue={e.lastname ? e.lastname : ''}
                                  disabled = {e.hasGuest ? true : false}
                                />
                                <Input
                                  className="guest-email"
                                  placeholder="email address"
                                  defaultValue={e.email ? e.email : ''}
                                  disabled = {e.hasGuest ? true : false}
                                />
                              </Flex>
                            )}
                            {e.error && (
                              <span className="left-align-text red-text">
                                Please enter recipients name and email.
                              </span>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Flex>
                    }
                    
                    {/* Share Trip Button */}
                    <Flex
                      w={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      pt={4}
                    >
                      <Link
                        href="#"
                        m={2}
                        w={{ base: "100%", lg: "100%" }}
                        className="no-underline"
                      >
                        <Button
                          onClick={() => {
                            sendGuestInfo();
                            // Use setTimeout to delay the toast
                            setTimeout(() => {
                              toast({
                                title: 'Trip Shared',
                                description: "Your trip has been shared with your guests.",
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                              });
                            }, 750); // adjust the delay as needed
                          }}
                          className="dm-button-orange-pill share-page-submit"
                          w={{ base: "100%", lg: "100%" }}
                          boxShadow="base"
                        >
                          Share this trip
                        </Button>
                      </Link>
                    </Flex>
                    {/* End Share Trip Button */}
                    {/* End Show Invite Additional Guests Section */}

                  </Box>
                </Box>
              </Flex>
            </Flex>
            ) : (
            <>
              <Flex justifyContent={'center'} w={'100%'} textAlign='center' pt={{base:3, md:12}} pb={2}>You do not have any reservations that can currently be shared.</Flex>
              <Link
                href="https://book.thisisdelmar.com/"
                target="_blank"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-orange-pill"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={'90vw'}
                  boxShadow="base"
                  my={12}
                >
                  Explore more homes
                </Button>
              </Link>
              <Link
                href="/"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-navy-outline"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={'90vw'}
                  boxShadow="base"
                  mb={12}
                >
                  Back to Home
                </Button>
              </Link>
            </>
            )
          }

        </Flex>
        : reservationLoaded ?
        <Flex w={"100%"} h={'80vh'}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Text mb={6}>No Matching Reservation Found</Text>
          <Flex gap={4} flexDirection={{base:'column', md:'row'}} w={{ base: "100%", lg: "auto" }}>
            <Link
              href="/trips"
              w={{ base: "100%", lg: "auto" }}
              className="no-underline"
            >
              <Button
                className="dm-button-orange-pill"
                w={{ base: "100%", lg: "300px" }}
                maxW={'90vw'}
                boxShadow="base"
              >
                Back to My Trips
              </Button>
            </Link>
            <Link
              href='/'
              target="_self"
              w={{ base: "100%", lg: "auto" }}
              className="no-underline"
            >
            <Button
              className="dm-button-navy-outline"
              w={{ base: "100%", lg: "300px" }}
              maxW={'90vw'}
              boxShadow="base"
              onClick={() => window.open('/','_self')}
            >
              Start a New Search
            </Button>
            </Link>
          </Flex>
        </Flex>
        :
        <Flex w={'100%'} h={'90vh'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex>
      }
      </Flex>

      {isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};

export { Share };

