import { useUser } from "@clerk/clerk-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ListingBlock } from "../components/blocks/listing/Listing";
import { SkeletonBlock } from "../components/blocks/listing/Skeleton";
import { fetchHouseDetails } from "../helpers/fetch";
import { omitNullAndUndefined } from "../helpers/helpers";
import { sendHubspotEvent } from "../helpers/hubspot";

export const Listing = () => {
  const [houseLoading, setHouseLoading] = useState(true);
  const navigate = useNavigate();
  let { houseNumber } = useParams();
  const location = useLocation();
  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;
  const eventFiredRef = useRef(false);  // Track if event has been fired

  const rebooReservationParam = localStorage.getItem("dm-rebook-reservation")
  const attributionParam = localStorage.getItem("dm-attribution")
  const eventName = "pe21053466_house_listing_page_viewed";
  const properties = omitNullAndUndefined({
    house_number: houseNumber,
    rebook_reservation_source: rebooReservationParam,
    attribution: attributionParam,
    hs_page_url: window.location.href,
  })
  const eventData = {
    eventName,
    properties,
    ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
  };
  useEffect(() => {
    if (!eventFiredRef.current && Object.keys(properties).length > 0) {
      // Fire the event only once
      eventFiredRef.current = true;
      (async () => {
        try {
          console.log('Sending eventData:', eventData);
          await sendHubspotEvent(eventData);
        } catch (error) {
          console.error('Error sending HubSpot event:', error);
        }
      })();
    }
  }, [properties]); // Only re-run if `properties` change

  const navigateToCorrectPath = async () => {
    const queryParams = new URLSearchParams(location.search);
    const isGoogleVacationRental = queryParams.get("pointofsale") === "google";
    if (isGoogleVacationRental) {
      const response = await fetchHouseDetails({ guestyHouseId: houseNumber });
      if (!response.ok) {
        throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
      }
      const { results: house } = await response.json();
      const startdate = queryParams.get("checkIn");
      const enddate = queryParams.get("checkOut");
      const guests = queryParams.get("minOccupancy");

      const newParams = new URLSearchParams();
      if (startdate) newParams.set("startdate", startdate);
      if (enddate) newParams.set("enddate", enddate);
      if (guests) newParams.set("guests", guests);
      navigate(`/${house.houseNumber}?${newParams.toString()}`, {
        replace: true,
      });
    }

    setHouseLoading(false);
  };

  useEffect(() => {
    navigateToCorrectPath();
  }, [navigate, houseLoading]);

  if (houseLoading) return <SkeletonBlock />;

  return <ListingBlock />;
};
